import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
} from 'react';
import { StaticQuery, graphql } from 'gatsby';
import cn from 'classnames';

// Atoms
import Hamburger from '../atoms/hamburger';
import Button from '../atoms/button';

// Components
import {
  headerHeight,
  breakpoints,
  generateUrlFromContentModel,
  useScroll,
} from './helpers';
import { AppContext } from './app';

// Logos
import Logo from '../logos/logo.png';
import ReversedLogo from '../logos/logo-reversed.png';

const ArrowComponent = ({ className }) => {
  return (
    <svg
      className={className}
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke-current"
        d="M1.75 1.125L6.5 5.875L11.25 1.125"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DesktopLinkComponent = ({ nav }) => {
  return (
    <a
      href={nav.url}
      className="hover:underline"
      aria-label={`Navigating to ${nav.label} page`}
    >
      {nav.label}
    </a>
  );
};
const DesktopLinkGroupComponent = ({ nav, index }) => {
  return (
    <div className="relative inline-block cursor-default group">
      <span className="flex items-center">
        <span className="mr-1">{nav.label}</span>
        <ArrowComponent className="text-inherit -translate-y-[2px] transition-transform group-hover:-rotate-180" />
      </span>
      <div className="absolute min-w-[160px] top-full left-1/2 -translate-x-1/2 transition-opacity opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
        <div className="bg-white border-[2px] border-solid border-grey-mid rounded-xl px-3 py-1 mt-5 shadow-nav-group before:absolute before:w-4 before:h-4 before:top-3 before:left-1/2 before:-translate-x-1/2 before:bg-white before:rotate-45 before:border-t-[2px] before:border-l-[2px] before:border-solid before:border-grey-mid">
          <ul className="relative flex flex-col list-none">
            {nav.links.map((item, itemIndex) => {
              return (
                <li
                  key={`desktop-sub-nav-${index}-${itemIndex}`}
                  className={`py-4 px-2 ${
                    itemIndex !== 0
                      ? 'border-t-[1px] border-solid border-grey-mid'
                      : ''
                  }`}
                >
                  <a
                    href={item.url}
                    className="hover:underline text-black"
                    aria-label={`Navigating to ${item.label} page`}
                  >
                    {item.label}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

const MobileLinkComponent = ({ nav }) => {
  return (
    <div className="w-full h-[50px] flex items-center px-4">
      <a
        href={nav.url}
        className="hover:underline"
        aria-label={`Navigating to ${nav.label} page`}
      >
        {nav.label}
      </a>
    </div>
  );
};
const MobileLinkGroupComponent = ({ nav, index }) => {
  const accordionWrapper = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    accordionWrapper.current.style.maxHeight = isOpen
      ? `${accordionWrapper.current.scrollHeight}px`
      : '0px';
  }, [isOpen]);

  useLayoutEffect(() => {
    accordionWrapper.current.style.maxHeight = '0px';
  });

  return (
    <div>
      <div className="w-full h-[50px] flex items-center px-4">
        <button
          onClick={toggleAccordion}
          type="button"
          className="flex items-center"
        >
          <span className="mr-3">{nav.label}</span>
          <ArrowComponent
            className={`text-blue-ocean -translate-y-[2px] transition ${
              isOpen ? '-rotate-180' : 'rotate-0'
            }`}
          />
        </button>
      </div>

      <div
        ref={accordionWrapper}
        className="overflow-hidden transition-[max-height]"
      >
        <ul className="list-none bg-grey-light py-2">
          {nav.links.map((item, itemIndex) => {
            return (
              <li
                key={`mobile-sub-nav-${index}-${itemIndex}`}
                className="w-full h-[50px] flex items-center px-8"
              >
                <a
                  href={item.url}
                  className="hover:underline text-black"
                  aria-label={`Navigating to ${item.label} page`}
                >
                  {item.label}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const desktopNavTypes = {
  link: DesktopLinkComponent,
  group: DesktopLinkGroupComponent,
};
const mobileNavTypes = {
  link: MobileLinkComponent,
  group: MobileLinkGroupComponent,
};

const Header = () => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        allContentfulGlobalOption(filter: { publishable: { eq: "Publish" } }) {
          nodes {
            headerNavigations {
              ... on Node {
                ...homeNavFragment
                ...standardNavFragment
                ...builderNavFragment
                ...demoNavFragment
                ...contactNavFragment
                ...careersNavFragment
              }
            }
            headerCtas {
              ...buttonFragment
            }
            headerNavigations2 {
              id
              label
              type
              url
              links {
                label
                url
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const params = data.allContentfulGlobalOption.nodes[0];

      const {
        menuIsOpen,
        setMenuIsOpen,
        headerIsShrink,
        setHeaderIsShrink,
        bodyLock,
      } = useContext(AppContext);
      const { scrollTop, previousScrollTop } = useScroll(50); // check every 50ms

      useEffect(() => {
        if (previousScrollTop < 0) return;
        if (scrollTop + window?.innerHeight >= document?.body?.offsetHeight)
          return;
        if (bodyLock) return;
        if (
          scrollTop > previousScrollTop &&
          scrollTop !== -1 &&
          scrollTop >=
            (window?.innerWidth < breakpoints.lg
              ? headerHeight.mobile / 4
              : headerHeight.desktop / 4)
        ) {
          setHeaderIsShrink(true);
        } else if (
          scrollTop < previousScrollTop &&
          scrollTop <
            (window?.innerWidth < breakpoints.lg
              ? headerHeight.mobile / 4
              : headerHeight.desktop / 4)
        ) {
          setHeaderIsShrink(false);
        }
      }, [scrollTop]);

      const classes = cn(
        'pt-[29px] transition-all h-header-height-mobile lg2:h-header-height-desktop',
        headerIsShrink
          ? `lg2:pt-[21px] bg-white shadow-nav text-black`
          : `lg2:pt-9 bg-transparent text-white`
      );

      return (
        <div className="fixed top-0 inset-x-0 z-50">
          <div className="bg-blue-bold py-2 text-body-sm text-white text-center">
            <p>
              Check out{' '}
              <a href="/showroomnext" className="underline hover:no-underline">
                SHOWROOMnext
              </a>{' '}
              &mdash; Get on board
            </p>
          </div>

          <div
            className={classes}
            style={{ maxHeight: headerIsShrink ? '90px' : '120px' }}
          >
            <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
              <div className="flex justify-between items-center">
                <a
                  href="/"
                  className="block mr-6"
                  aria-label="Navigate back to Rev's home page"
                >
                  {menuIsOpen || headerIsShrink ? (
                    <img
                      src={Logo}
                      alt=""
                      className="max-w-[174px] sm:max-w-[261px] lg:max-w-[366px] max-h-[20px] sm:max-h-[30px] lg:max-h-[42px]"
                    />
                  ) : (
                    <img
                      src={ReversedLogo}
                      alt=""
                      className="max-w-[174px] sm:max-w-[261px] lg:max-w-[366px] max-h-[20px] sm:max-h-[30px] lg:max-h-[42px]"
                    />
                  )}
                </a>
                <div className="hidden lg2:flex lg:ml-auto">
                  {params.headerNavigations2.length > 0 && (
                    <ul className="list-none flex justify-between items-center space-x-8">
                      {params.headerNavigations2.map((nav, index) => {
                        const Component = desktopNavTypes[nav.type];
                        return (
                          <li key={`desktop-nav-${index}`} className="text-nav">
                            <Component nav={nav} index={index} />
                          </li>
                        );
                      })}
                    </ul>
                  )}

                  {params.headerCtas && params.headerCtas.length > 0 && (
                    <div className="flex justify-between items-center space-x-10 ml-10">
                      {params.headerCtas.map((cta, key) => (
                        <Button
                          key={`desktop-cta-${key}`}
                          as={
                            (cta?.internalUrl && cta?.internalUrl.length) ||
                            cta?.externalUrl
                              ? 'a'
                              : 'button'
                          }
                          variant={cta?.variant.toLowerCase()}
                          href={
                            cta?.internalUrl && cta?.internalUrl.length
                              ? generateUrlFromContentModel(cta?.internalUrl[0])
                              : cta?.externalUrl
                          }
                          label={cta?.label || cta.title}
                          aria-label={cta?.ariaLabel}
                          newTab={cta?.opensInNewTab}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <Hamburger />
              </div>
            </div>
            <div
              id="site-menu"
              aria-labelledby="hamburger"
              className={`fixed inset-0 -z-[1] transform transition-transform lg2:hidden ${
                menuIsOpen ? 'translate-x-0' : 'translate-x-full'
              }`}
            >
              <div className="absolute inset-x-0 top-0 min-h-[50vh] max-h-[90vh] bg-white text-black pb-10 flex flex-col z-10 pt-menu">
                <div className="overflow-y-auto">
                  {params.headerNavigations2.length > 0 && (
                    <ul className="list-none space-y-2 mb-10">
                      {params.headerNavigations2.map((nav, index) => {
                        const Component = mobileNavTypes[nav.type];
                        return (
                          <li
                            key={`mobile-nav-${index}`}
                            className="text-nav-m"
                          >
                            <Component nav={nav} index={index} />
                          </li>
                        );
                      })}
                    </ul>
                  )}

                  {params.headerCtas && params.headerCtas.length > 0 && (
                    <div className="text-center space-y-10 mt-auto px-10">
                      {params.headerCtas.map((cta, key) => (
                        <Button
                          key={`mobile-cta-${key}`}
                          as={
                            (cta?.internalUrl && cta?.internalUrl.length) ||
                            cta?.externalUrl
                              ? 'a'
                              : 'button'
                          }
                          variant={cta?.variant.toLowerCase()}
                          href={
                            cta?.internalUrl && cta?.internalUrl.length
                              ? generateUrlFromContentModel(cta?.internalUrl[0])
                              : cta?.externalUrl
                          }
                          label={cta?.label || cta.title}
                          aria-label={cta?.ariaLabel}
                          newTab={cta?.opensInNewTab}
                          className="w-full"
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <button
                type="button"
                className="absolute inset-0 bg-black opacity-70 -z-1"
                aria-label="Show/hide the navigation menu"
                onClick={(e) => {
                  e.preventDefault();
                  setMenuIsOpen(!menuIsOpen);
                }}
              />
            </div>
          </div>
        </div>
      );
    }}
  />
);

export default Header;
