/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

// Components
import { AppContext } from './app';
import { useScroll } from './helpers';

function Seo({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title || site.siteMetadata?.title;

  const { bodyLock } = useContext(AppContext);

  const { previousScrollTop } = useScroll(250);

  const [temp, setTemp] = useState(0);

  useEffect(() => {
    if (bodyLock) {
      document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden');
      document.body.setAttribute('style', `top:-${previousScrollTop}px`);
      setTemp(previousScrollTop);
    } else {
      document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden');
      document.body.removeAttribute('style');
      window.scrollTo(0, temp);
    }
  }, [bodyLock]);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `${defaultTitle} | %s` : '%s'}
      link={[
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap',
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `/og-facebook-image.jpg`,
        },
        {
          property: 'og:image:type',
          content: 'image/jpeg',
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:width`,
          content: `630`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: `/og-twitter-image.jpg`,
        },
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
