import * as React from 'react';

// Atoms
import TextField from '@mui/material/TextField';
import Button from '../atoms/button';

// Components
import Layout from '../components/layout';
import Seo from '../components/seo';

// Icons
import DownloadIcon from '../icons/icon-download.svg';
import LeftIcon from '../icons/icon-left.svg';
import RightIcon from '../icons/icon-right.svg';
import SimpleLogo from '../logos/logo-simple.svg';
import EmblemLogo from '../logos/logo-emblem.svg';

const StyleguidePage = () => (
  <Layout>
    <Seo title="Styleguide page" />
    <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
      <h1 className="u-h1">Heading 1</h1>
      <h2 className="u-h2">Heading 2</h2>
      <h3 className="u-h3">Heading 3</h3>
      <h4 className="u-h4">Heading 4</h4>
      <h5 className="u-h5">Heading 5</h5>
      <p className="u-body-lg">Body Large</p>
      <p className="u-body-md">Body Medium</p>
      <p className="u-body-sm">Body Small</p>
    </div>
    <hr />
    <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
      <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
        {[...Array(12)].map((i, key) => (
          <div key={key} className="w-full aspect-square bg-black" />
        ))}
      </div>
    </div>
    <br />
    <hr />
    <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
      <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
        <div className="w-full aspect-square bg-grey-light" />
        <div className="w-full aspect-square bg-grey-mid" />
        <div className="w-full aspect-square bg-grey-font-1" />
        <div className="w-full aspect-square bg-grey-font-2" />
        <div className="w-full aspect-square bg-grey-disabled" />
      </div>
    </div>
    <br />
    <hr />
    <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
      <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
        <div className="w-full aspect-square bg-blue-navy" />
        <div className="w-full aspect-square bg-blue-bold" />
      </div>
    </div>
    <br />
    <hr />
    <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
      <div className="inline-flex items-center gap-4">
        <Button
          as="a"
          newTab
          variant="primary"
          href="/"
          label="Speak to our team"
          aria-label="Navigating to home page"
        />
        <Button
          as="a"
          variant="secondary"
          href="/"
          label="Secondary CTA"
          aria-label="Navigating to home page"
        />
        <Button
          as="a"
          variant="secondary"
          href="/"
          label="Download"
          aria-label="Download document"
          icon={
            <DownloadIcon className="fill-current stroke-current w-8 h-8" />
          }
        />
        <Button
          as="a"
          variant="tertiary"
          href="/"
          label="Tertiary CTA"
          aria-label="Navigating to home page"
        />
        <Button
          variant="navigation"
          href="/"
          aria-label="Trigger previous call to action"
          icon={<LeftIcon className="fill-current w-8 h-8" />}
        />
      </div>
    </div>
    <br />
    <hr />
    <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
      <div className="inline-flex items-center gap-4">
        <DownloadIcon className="fill-current stroke-current w-8 h-8" />
        <LeftIcon className="fill-current w-8 h-8" />
        <RightIcon className="fill-current w-8 h-8" />
        <img src={SimpleLogo} alt="" />
        <img src={EmblemLogo} alt="" />
      </div>
    </div>
    <br />
    <hr />
    <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
      <div className="inline-flex justify-stretch items-start gap-4 w-full">
        <TextField
          id="first-name"
          label="First Name"
          variant="outlined"
          fullWidth
        />
        <TextField
          id="middle-name"
          label="Middle Name"
          variant="outlined"
          disabled
          fullWidth
        />
        <TextField
          id="last-name"
          label="Last Name"
          variant="outlined"
          error
          defaultValue="foo"
          fullWidth
        />
      </div>
    </div>
  </Layout>
);

export default StyleguidePage;
