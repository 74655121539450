import React, { useContext } from 'react';

// Components
import { AppContext } from '../components/app';

const Hamburger = () => {
  const { menuIsOpen, setMenuIsOpen, headerIsShrink } = useContext(AppContext);

  return (
    <button
      id="hamburger"
      type="button"
      className="relative w-8 h-8 lg2:hidden"
      aria-label="Show/hide the navigation menu"
      aria-controls="site-menu"
      aria-expanded={menuIsOpen}
      onClick={(e) => {
        e.preventDefault();
        setMenuIsOpen(!menuIsOpen);
      }}
    >
      <span
        className={`transition-all absolute top-1/4 right-0 transform h-[3px] w-full ${
          headerIsShrink ? 'bg-blue-ocean' : 'bg-white'
        } ${
          menuIsOpen
            ? 'top-1/2 -translate-y-1/2 rotate-45 bg-blue-ocean'
            : 'bg-white'
        }`}
      />
      <span
        className={`transition-all absolute top-1/4 right-0 transform h-[3px] ${
          headerIsShrink ? 'bg-blue-ocean' : 'bg-white'
        } ${
          menuIsOpen
            ? 'w-full top-1/2 -translate-y-1/2 -rotate-45 bg-blue-ocean'
            : 'translate-y-[9px] w-9/12 bg-white'
        }`}
      />
    </button>
  );
};

export default Hamburger;
